<template>
	<div class="box">
		<div class="headerTop"></div>
		<div class="conHote">
			<!-- 房间信息 -->
			<div class="dataTime">
				<p style="margin: 10px 0;font-weight: bold;">郑州方特欢乐世界1日成人票</p>
				<!-- <div> -->
				<p style="margin: 10px 0;font-weight: bold;">选择日期</p>
				<div class="list">
					<div v-for="(item,index) in xdata" :key="index" :class="{active:currentIndex === index}"
						class="listData" @click="dataList(item,index)">
						<p>{{item}}</p>
						<p style="color:#FC6228;">￥148</p>
					</div>
				</div>
				<!-- </div> -->
				<div class="chessNum">
					<p style="margin: 10px 0;font-weight: bold;">选择数量</p>
					<van-stepper v-model="value" theme="round" button-size="22" disable-input  max="5"/>
				</div>
				<p style="margin: 10px 0;color:#999999;font-size: 11px;">每单限购5张，同一身份证当天限购5张</p>
			</div>
			<!-- 费用明细 -->
			<div class="breakdown">
				<h4>选择游客</h4>
				<ul class="mealList">
					<li v-for="(v,k) in listName" @click="activeClick(k)" :class="{active1:index == k}">{{v.name}}</li>
					<button class="add" @click="showPopup">新增</button>
				</ul>
				<!-- <van-cell is-link @click="showPopup">展示弹出层</van-cell> -->
				<van-popup v-model:show="show" closeable>
					<van-cell-group>
						<van-field v-model="name" label="用户名" placeholder="请输入用户名" />
						<van-field v-model="tel" label="手机号" placeholder="请输入手机号" />
						<button class="add" @click="add">添加</button>
					</van-cell-group>
				</van-popup>
				<div v-for="(item,index) in listName" class="listName">
					<div style="display: flex;align-items: center;">
						<div>
							<p>游客</p>
							<p @click="close(index)"><van-icon name="close" /></p>
						</div>
						<div style="margin-left: 10px;">
							<p>{{item.name}}</p>
							<p>手机号{{item.tel}}</p>
						</div>
					</div>
					<div style="color: #0086F7;">编辑</div>
				</div>
			</div>
		</div>
		<div class="footerSub">
			<p>合计: <span>￥2367元</span></p>
			<button>去付款</button>
		</div>
	</div>
</template>

<script>
	import {
		ref
	} from 'vue';
	import {
		Dialog,
		Toast
	} from 'vant';
	export default {
		setup() {
			const show = ref(false);
			const showPopup = () => {
				show.value = true;
			};
			return {
				show,
				showPopup,
			};
		},
		data() {
			return {
				xdata: [],
				currentIndex: 0,
				value: 1,
				listName:[
					{
						name:"巴卡巴卡",
						tel:"18888888888"
					}
				],
				name:"",
				tel:"",
				index:0,
			}
		},
		created() {
			this.chart2()
		},
		methods: {
			activeClick(k) {
				this.index = k;
			},
			add() {
				console.log(this.name,this.tel)
				let obj = {}
				let key = "name";
				let value = this.name;
				let key1 = "tel";
				let value1 = this.tel;
				obj[key] = value;
				obj[key1] = value1;
				console.log(obj)
				if(this.name == ""){
					console.log("空")
				}else{
					if(this.tel == ""){
						Toast('手机号不能为空');
						return
					}
					if(this.name == ""){
						Toast('用户名不能为空');
						return
					}
					this.listName.push(obj)
					this.show = false
				}
				console.log(this.listName)
			},
			close(index){
				console.log(index)
				this.listName.splice(index,1)
				console.log(this.listName)
			},
			getDay(day) {
				var today = new Date();
				var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
				today.setTime(targetday_milliseconds); //注意，这行是关键代码
				var tYear = today.getFullYear();
				var tMonth = today.getMonth();
				var tDate = today.getDate();
				tMonth = this.doHandleMonth(tMonth + 1);
				tDate = this.doHandleMonth(tDate);
				return tYear + "-" + tMonth + "-" + tDate;
			},
			doHandleMonth(month) {
				var m = month;
				if (month.toString().length == 1) {
					m = "0" + month;
				}
				return m;
			},
			chart2(datas) {
				// var xdata=[];
				for (var i = 0; i < 14; i++) {
					this.xdata[i] = this.getDay(+i);
				}
				// console.log(this.xdata)
			},
			dataList(data, index) {
				console.log(data, index)
				this.datas = data
				this.currentIndex = index
			},
		}
	}
</script>

<style lang="less" scoped>
	p {
		margin: 0;
		padding: 0;
	}
	.active1 {
		color: #FC6228;
		border: solid 1px #FC6228 !important;
		width: 87px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		border-radius: 5px;
	}

	.active {
		width: 77px !important;
		height: 55px !important;
		background: #FDF1ED !important;
		border: 1px solid #FC6228 !important;
		border-radius: 5px !important;
	}

	.box {
		background-color: #f6f6f6;
		.headerTop {
			width: 100%;
			height: 2.5rem;
			background-color: #FC6228;
		}
		.conHote {
			width: 100%;
			// height: 3rem;
			// border: solid 1px #007AFF;
			margin-top: -1.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			.dataTime {
				width: 88%;
				// height: 2.3rem;
				background: #FFFFFF;
				border-radius: 20px;
				margin-bottom: 0.15rem;
				font-size: 0.2rem;
				padding: 15px;
				.list {
					width: 100%;
					// margin-top: 0.3rem;
					display: flex;
					overflow-x: scroll;
					white-space: nowrap;
					.listData {
						// font-size: 0.4rem;
						width: 77px;
						height: 55px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						background: #FFFFFF;
						border: 1px solid #E1E1E1;
						border-radius: 5px;
						text-align: center;
						// margin: 0 10px;
						margin-right: 10px;
						padding: 0 14px;
						color: #1A1A1A;
					}
				}

				.chessNum {
					margin: 10px 0;
					display: flex;
					align-items: center;
					justify-content: space-between;
					::v-deep .van-stepper__plus {
						background-color: #FC6228;
					}
					
					::v-deep .van-stepper__minus {
						color: #FC6228;
						border-color: #FC6228;
					}
				}
			}

			.breakdown {
				width: 88%;
				// height: 2.6rem;
				background: #FFFFFF;
				border-radius: 20px;
				margin-bottom: 1.2rem;
				font-size: 0.2rem;
				padding: 15px;
				h4 {
					margin: 0;
					padding: 0;
					font-size: 16px;
				}
				.mealList{
					display: flex;
					align-items: center;
					li{
						margin-right: 5px;
						border: solid 1px #666;
						width: 87px;
						height: 34px;
						border-radius: 5px;
						text-align: center;
						line-height: 34px;
						margin-top: 10px;
					}
				}
				::v-deep .van-popup {
					width: 85%;
					padding: 15px 0;
					.van-field__label{
						width: 50px;
					}
					.van-cell-group{
						display: flex;
						flex-direction: column;
						align-items: center;
					}
				}

				.add {
					width: 87px;
					height: 34px;
					background: #FC6228;
					border: none;
					border-radius: 5px;
					color: #FFFFFF;
					margin-top: 10px;
				}
				.listName{
					width: 100%;
					margin-top: 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

			}
		}

		.footerSub {
			position: fixed;
			bottom: 0;
			width: 100%;
			height: 0.8rem;
			font-size: 0.2rem;
			background: #FFFFFF;
			display: flex;
			align-items: center;
			justify-content: space-between;

			p {
				margin-left: 0.2rem;

				span {
					color: #FC6228;
				}
			}

			button {
				margin-right: 0.2rem;
				width: 100px;
				height: 35px;
				background: #FC6228;
				border-radius: 10px;
				border: none;
				color: #FFFFFF;
			}
		}
	}
</style>
